import React, { Fragment, useEffect, useState } from 'react'
import { Col, Card, Form, Input, Label, Row, Button, Spinner, Table, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap"
import { Controller, useForm, useWatch } from "react-hook-form"
import { useNavigate, useParams } from 'react-router'
import toast from 'react-hot-toast'
import ReactSelect from 'react-select'
import { JobService } from '../../modules/Services/Job'
import { CustomerService } from '../../modules/Services/Customers'
import { preventDefualt, selectStyle, selectThemeColors } from '../../modules/commonModel/Utils'
import ConfirmModal from '../../modules/commonModel/ConfirmModal'
import moment from 'moment'
import InvoiceModel from '../Invoices/InvoiceModel'
import AsyncSelect from 'react-select/async'
import { TagsService } from '../../modules/Services/Tags'
import { MoreVertical, Plus } from 'react-feather'
import Flatpickr from 'react-flatpickr'
import { JobLegsService } from '../../modules/Services/JobLegs'
import UILoader from '../../modules/commonModel/Ui-loader'
import ComponentSpinner from '../../modules/apps/user-management/users-list/components/loading/Loading-spinner'
import { LocationService } from '../../modules/Services/Location'
import { AddCustomer } from '../Customers/AddCustomer'
import { KTIcon } from '../../../_metronic/helpers'
import AsyncCreatableSelect from 'react-select/async-creatable';

const AddJob = () => {
  const { control, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm()
  const {id} = useParams()
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)
  const [currentCustomer, setCurrentCustomer] = useState({ value: '', label: 'Select Client'})
  const [statusData, setStatusData] = useState([])
  const [currentStatus, setCurrentStatus] = useState({ value: null, label: 'Select Status'})
  const [jobLegByJob, setJobLegByJob] = useState([])
  const [priceType, setPriceType] = useState({value: 0, label: 'Price by Booking'})
  const [jobPrice, setJobPrice] = useState(null)
  const [priceData, setPriceData] = useState({ id: null, customer_id: null, customer_name: null, company_name: null, booking_number: null, job_name: null, job_price: null, job_leg_sum: null, job_status: null, tags: null})
  const [openInvoice, setOpenInvoice] = useState(false)
  const [centeredModal, setCenteredModal] = useState(false)
  const [pending, setPending] = useState(false)
  const entity_id = localStorage.getItem('entity_id')
  const [render, setRender] = useState(false)
  const [flag, setFlag] = useState(false)
  const [tableHeight, setTableHeight] = useState(0)
  const [openDeleteJobModal, setOpenDeleteJobModal] = useState(false)
  const [deleteJobId, setDeleteJobId] = useState(false)
  const [editLoader, setEditLoader] = useState(false)
  const [renderElementService, setRenderElementService] = useState(1)
  const [check, setCheck] = useState({ timeout: null })
  const [purchaseOrder, setPurchaseOrder] = useState(null)
  const [notes, setNotes] = useState('')

  const watchQs = useWatch({ name: "jobs", control })
  const [customerSearch, setCustomerSearch] = useState('')
  const [openCustomerModel, setOpenCustomerModel] = useState(false)
  const [customerName, setCustomerName] = useState({ value: null, label: 'select' })
  const [searchBy, setSearchBy] = useState('customer_name')
  const [createJobLoader, setCreateJobLoader] = useState(false)

  let customerTimeoutId: ReturnType<typeof setTimeout> | null = null;
  let locationTimeoutId: ReturnType<typeof setTimeout> | null = null;

  // const [tagData, setTagData] = useState([])
  // const [currentTag, setCurrentTag] = useState<TagOption[]>([])
  // type TagOption = {
  //   label: string;
  //   value: string;
  // };

  const priceTypeData = [
    { value: 0, label: 'Price by Booking'},
    { value: 1, label: 'Price by Jobs(s)'}
  ]

  async function fetchFormData() {
    setLoader(true)

    // const jobsData = data?.jobs?.map((item: any) => {
    // const jobsData = watchQs?.map((item: any) => {
    //   return {...item, pick_up: item?.pick_up?.value, drop_off: item?.drop_off?.value}
    // })

    const jobsData = watchQs?.map((item: any) => {
      return {...item, pick_up_location: item?.pick_up_location?.value, drop_off_location: item?.drop_off_location?.value}
    })

    const obj = {
      entity_id: entity_id,
      search_by_client: searchBy === 'customer_name' ? 0 : searchBy === 'company_name' ? 1 : 0,
      customer_id: currentCustomer?.value,
      price_by_job: priceType?.value === 0 ? 1 : 0,
      price_by_job_legs: priceType?.value === 1 ? 1 : 0,
      job_price: jobPrice,
      // job_price: data?.price,
      job_status: currentStatus?.value,
      //tags: currentTag?.map((item) => { return item?.value }),
      coachiejoblegs: jobsData,
      purchase_order:purchaseOrder,
      booking_notes: notes
    }
    if (id) delete obj.entity_id

    return JobService[id ? 'updateJob' : 'storeJob'](entity_id, obj, id)
      .then((res: any) => {
        const data = res?.data?.response
        toast.success(res?.data?.message)
        setPriceData(data)
        // setCurrentTag([])
        if (!id) navigate(`/booking/${data?.id}/edit`)
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message)
      })
      .finally(() => setLoader(false))
  }

  function append(data: any) {
    const arr = data?.map((item: any) => {
      const obj = {
        id: item?.id,
        pax: item?.pax,
        vehicles_required: item?.vehicles_required,
        // pick_up: {value: item?.pick_up, label: item?.pick_up_location},
        // drop_off: {value: item?.drop_off, label: item?.drop_off_location},
        pick_up_location: {value: item?.pick_up_location, label: item?.pick_up_location},
        drop_off_location: {value: item?.drop_off_location, label: item?.drop_off_location},
        // start_at: item?.start_at,
        // end_at: item?.end_at,
        start_at_date: item?.start_at_date,
        start_at_time: item?.start_at_time,
        end_at_date: item?.end_at_date,
        end_at_time: item?.end_at_time,
        cost: item?.cost
      }
      return obj
    })
    return arr
  }

  function editData() {
    setEditLoader(true)
    JobService.editJob(entity_id, id)
      .then((res: any) => {
        setJobLegByJob(res?.data?.response?.coachiejoblegs)
        setSearchBy(res?.data?.response?.search_by_client === 1 ? 'company_name' : res?.data?.response?.search_by_client === 0 ? 'customer_name' : 'customer_name')
        setPriceType(res?.data?.response?.price_by_job === 1 ? {value: 0, label: 'Price by Booking'} : res?.data?.response?.price_by_job_legs === 1 ? {value: 1, label: 'Price by Jobs(s)'} : {value: 0, label: 'Price by Booking'})
        setPriceData(res?.data?.response)
        setCurrentCustomer({ value: res?.data?.response?.customer_id, label: res?.data?.response?.customer_name })
        setJobPrice(res?.data?.response?.price_by_job === 1 ? res?.data?.response?.job_price : res?.data?.response?.price_by_job_legs === 1 ? res?.data?.response?.job_leg_sum : null)
        setPurchaseOrder(res?.data?.response?.purchase_order)
        setNotes(res?.data?.response?.booking_notes)
        const obj = {
          booking_number: res?.data?.response?.booking_number,
          customer: { value: res?.data?.response?.customer_id, label: res?.data?.response?.customer_name },
          price: res?.data?.response?.price_by_job === 1 ? res?.data?.response?.job_price : res?.data?.response?.price_by_job_legs === 1 ? res?.data?.response?.job_leg_sum : null,
          jobs: append(res?.data?.response?.coachiejoblegs)
        }
        reset(obj)
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message)
      })
      .finally(() => setEditLoader(false))
  }


  useEffect(() => {
    if (priceData && statusData?.length > 0 ) {  //&& tagData?.length > 0
      const obj = getValues()    
      statusData?.map((item) => {
        if (priceData?.job_status?.id === item?.value) {
          obj.status = item
          setCurrentStatus(item)
        }
      })
      // setCurrentTag([])
      // tagData?.filter(item => {
      //   priceData?.tags?.filter((i: any) => {
      //     if (item.value === i?.id) {
      //       setCurrentTag((p: any) => [...p, item])
      //     }
      //   })
      // })
      reset(obj)
    }
  }, [priceData, statusData]) //, tagData

  const loadOptions = (inputValue: any, callback: any) => {
    if (customerTimeoutId) {
      clearTimeout(customerTimeoutId);
    }
    customerTimeoutId = setTimeout(() => {
      setCustomerSearch(inputValue)
      const obj = { 
        getall: 1,
        fields: `id,${searchBy}`,
        searchByName:searchBy,
        customer_name : inputValue
      }
      CustomerService.getCustomerList(entity_id, obj)
      .then((res: any) => {
        callback(res?.data?.response?.map((item: any) => {
          return { value: item?.id, label: item?.[searchBy]}
        }))
      })
      .catch(err => console.log(err?.response?.data?.message))
    }, 1000)
  }

  function getJobStatusList() {
    JobService.getJobStatusList(entity_id)
    .then((res: any) => {
      const customerList = res?.data?.response?.map((item: any) => {
        return { value: item?.id, label: item?.label }
      })
      const dafault = { value: '', label: 'Select Status'}
      setStatusData([dafault, ...customerList])
    })
    .catch((err: any) => {
      toast.error(err?.response?.data?.message)
    })
  }

  // function getTagList() {
  //   const data = {
  //     getall: 1,
  //     fields: 'id,name'
  //   }
  //   TagsService.getTagsList(entity_id, data)
  //   .then((res: any) => {
  //     const tagsList = res?.data?.response?.map((item: any) => {
  //       return { value: item?.id, label: item?.name }
  //     })
  //     setTagData(tagsList)
  //   })
  //   .catch((err: any) => {
  //     toast.error(err?.response?.data?.message)
  //   })
  // }

  useEffect(() => {
    // getTagList()
    getJobStatusList()
  }, [])
  
  useEffect(() => {
    if (id) editData()
  }, [id, flag])

  useEffect(() => {
    if (!id && statusData?.length) {
      const obj = getValues()
      obj.status = { value: 5, label: 'New' }
      setCurrentStatus({ value: 5, label: 'New' })
      reset(obj)
    }
  }, [statusData])

  function forceDeleteBooking() {
    setPending(true)
    JobService.deleteJob(entity_id, id)
      .then((res: any) => {
        toast.success(res?.data?.message)
        setCenteredModal(false)
        navigate('/booking')
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message)
      })
      .finally(() => setPending(false))
  }

  function changeDate(key: any, value: any, item: any) {
    if ((key === 'start_at' || key === 'end_at' ) && item[key] === moment(value[0]).format('YYYY-MM-DD HH:mm:ss')) return

    setEditLoader(true)
    const data = {
      start_at: key === 'start_at' ? value[0] ? moment(value[0]).format('YYYY-MM-DD HH:mm') : null : item?.start_at,
      end_at: key === 'end_at' ?  value[0] ? moment(value[0]).format('YYYY-MM-DD HH:mm') : null : item?.end_at,
      vehicles_required: key === 'vehicles_required' ?  value ? value : null : item?.vehicles_required,
      pax : key === 'pax' ?  value ? value : null : item?.pax,
      // pick_up: key === 'pick_up' ?  value ? value?.value : null : item?.pick_up,
      // drop_off: key === 'drop_off' ?  value ? value?.value : null : item?.drop_off,
      pick_up_location: key === 'pick_up_location' ?  value ? value.value : null : item?.pick_up_location,
      drop_off_location: key === 'drop_off_location' ?  value ? value.value : null : item?.drop_off_location,
      cost: item?.cost
    }
    JobLegsService.updateJobLeg(entity_id, data, item?.id)
    .then((res: any) => {
      toast.success(res?.data?.message)
      setFlag(!flag)
    })
    .catch((err: any) => {
      toast.error(err?.response?.data?.message)
    })
  }

  function createCloneJob(item: any, field: any) {
    setEditLoader(true)

    fetchFormData()
    .then(() => {
      const data = {
        return_leg: field === 'return_job' ? 1 : null,
      };

      return JobLegsService.cloneJobLeg(entity_id, item?.id, data);
    })
    .then((res: any) => {
      toast.success(res?.data?.message);
      setFlag(!flag);
    })
    .catch((err: any) => {
      toast.error(err?.response?.data?.message);
    })
    .finally(() => setEditLoader(false));

    // const data = {
    //   return_leg: field === 'return_job' ? 1 : null
    // }

    // JobLegsService.cloneJobLeg(entity_id, item?.id, data)
    // .then((res: any) => {
    //   toast.success(res?.data?.message)
    //   setFlag(!flag)
    // })
    // .catch((err: any) => {
    //   toast.error(err?.response?.data?.message)
    // })
  }

  function deleteJob() {
    setEditLoader(true)
    setPending(true)

    fetchFormData()
    .then(() => {
      return JobLegsService.deleteJobLeg(entity_id, deleteJobId);
    })
    .then((res: any) => {
      toast.success(res?.data?.message)
      setOpenDeleteJobModal(false)
      setDeleteJobId(null)
      setFlag(!flag)
    })
    .catch((err: any) => {
      toast.error(err?.response?.data?.message);
    })
    .finally(() => setPending(false));
  
    // JobLegsService.deleteJobLeg(entity_id, deleteJobId)
    // .then((res: any) => {
    //   toast.success(res?.data?.message)
    //   setOpenDeleteJobModal(false)
    //   setDeleteJobId(null)
    //   setFlag(!flag)
    // })
    // .catch((err: any) => {
    //   toast.error(err?.response?.data?.message)
    // })
    // .finally(() => setPending(false))
  }

  useEffect(() => {
    if (window.innerHeight < 700){
      setTableHeight(200)
    } else if (window.innerHeight < 800){
      setTableHeight(300)
    } else if (window.innerHeight < 900){
      setTableHeight(400)
    } else if (window.innerHeight < 1000){
      setTableHeight(500)
    } else if (window.innerHeight < 1100){
      setTableHeight(600)
    }
  })

  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight < 700){
        setTableHeight(200)
      } else if (window.innerHeight < 800){
        setTableHeight(300)
      } else if (window.innerHeight < 900){
        setTableHeight(400)
      } else if (window.innerHeight < 1000){
        setTableHeight(500)
      } else if (window.innerHeight < 1100){
        setTableHeight(600)
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function deleteJobConfirmation(id: any) {
    setDeleteJobId(id)
    setOpenDeleteJobModal(true)
  }

  const loadLocationOptions = (inputValue: any, callback: any) => {
    if (locationTimeoutId) {
      clearTimeout(locationTimeoutId);
    }
    // if (inputValue?.length > 3) {
      locationTimeoutId = setTimeout(() => {
        const obj = { 
          name : inputValue
        }
        LocationService.getSuggestLocationList(entity_id, obj)
        .then((res: any) => {
          callback(res?.data?.response?.map((item: any) => {
            return { value: item?.location_name, label: item?.location_name }
          }))
        })
        .catch((err: any) => console.log(err?.response?.data?.message))
      }, 1000)
    // }
  }

  useEffect(() => {
    if (customerName?.value) {
      setValue('customer', customerName )
      setCurrentCustomer(customerName)
    }
  }, [customerName])

  useEffect(() => {
    if (searchBy === 'customer_name') {
      setValue('customer', { value: priceData?.customer_id, label: priceData?.customer_name })
      setCurrentCustomer({ value: priceData?.customer_id, label: priceData?.customer_name })
    } else if(searchBy === 'company_name') {
      setValue('customer', { value: priceData?.customer_id, label: priceData?.company_name })
      setCurrentCustomer({ value: priceData?.customer_id, label: priceData?.company_name })
    }
  }, [searchBy])

  function RadioButton ({ name, value, checked, setChecked, label }) {
    return (
      <>
        <Input type='radio' name={name} className='me-5 ml-5' value={value} checked={checked} onChange={(e) => setChecked(e.target.value)} />
        <Label className='form-label text-muted sm' for={name}>{label}</Label>
      </>
    )
  }

  function createNewJob() {
    setCreateJobLoader(true)
    const obj = {
      entity_id: entity_id,
      booking_id: id,
      start_at_date: moment(new Date()).format('YYYY-MM-DD'),
      start_at_time: moment(new Date()).format('HH:mm'),
      end_at_date: moment(new Date()).format('YYYY-MM-DD'),
      end_at_time: moment(new Date()).format('HH:mm'),
      cost: 0,
      status: 1,
      vehicles_required: 1
    }
    JobLegsService.storeJobLeg(entity_id, obj)
    .then((res: any) => {
      toast.success(res?.data?.message)
      setFlag(!flag)
    })
    .catch((err: any) => {
      toast.error(err?.response?.data?.message)
    })
    .finally(() => setCreateJobLoader(false))
  }

  document.addEventListener('DOMContentLoaded', function() {
    var timeInputs = document.querySelectorAll('input[type="time"]');
    timeInputs.forEach(function(input) {
      console.log('dgdfghdifgndfji')
        input.addEventListener('mousedown', function(event) {
            event.preventDefault();
        });
    });
  });
  
  return (
    <>
      <Fragment>
        <Offcanvas 
          isOpen={openCustomerModel} 
          toggle={() => setOpenCustomerModel(false)}
          direction='end'
          style={{width: '700px'}}
        >
          <OffcanvasHeader toggle={() => setOpenCustomerModel(false)}>
            <h2>Customer</h2>
          </OffcanvasHeader>
          <OffcanvasBody>
            <AddCustomer customerSearch={customerSearch} searchBy={searchBy} setSearchBy={setSearchBy} setOpenCustomerModel={setOpenCustomerModel} setCustomerName={setCustomerName} />
          </OffcanvasBody>
        </Offcanvas>
        <ConfirmModal
          isOpen={openDeleteJobModal}
          onHide={() => setOpenDeleteJobModal(false)}
          ondelete={deleteJob}
          loader={pending}
        />
        <ConfirmModal
          isOpen={centeredModal}
          onHide={() => setCenteredModal(false)}
          ondelete={forceDeleteBooking}
          loader={pending}
        />
        <Card className='p-10 w-100' style={{height: '88vh'}}>
          <UILoader blocking={editLoader} loader={<ComponentSpinner />} overlayColor='rgba(245, 245, 240, .1)' className={editLoader ? 'loader-blur' : ''}>
            <Form onSubmit={handleSubmit(fetchFormData)}>
              <Row className='w-100'>
                  {id &&
                    <Col md='4' className='mb-8 mt-2'>
                      <Label className='form-label' for='booking_number'>Booking ID</Label>
                      <Controller name='booking_number' control={control} rules={{required: true}} defaultValue=''
                        render={({ field }) => <Input placeholder='Enter Booking ID' disabled invalid={ errors.booking_number && false } {...field} />} 
                      />
                      {errors.booking_number && (<small className="text-danger ml-2 ">Booking ID is a required field</small>)}
                    </Col>
                  }
                  <Col md='4' className=''>
                    <Col md='12' className='mb-2 d-flex'>
                      <Label className='form-label me-5' for='customer'>Client</Label>
                      <div className='me-10'>
                        <RadioButton name='searchBy' value={'customer_name'} checked={searchBy === 'customer_name'} setChecked={setSearchBy} label='Individual' />
                      </div>
                      <div>
                        <RadioButton name='searchBy' value={'company_name'} checked={searchBy === 'company_name'} setChecked={setSearchBy} label='Company' />
                      </div>
                    </Col>
                    <Controller name='customer' control={control} rules={{required: true}} defaultValue=''
                      render={({ field: { onChange } }) => 
                        <AsyncSelect
                          isClearable={true}
                          className='react-select w-100 booking_zindex'
                          classNamePrefix='select'
                          placeholder='Search Client'
                          isRtl={false}
                          name='callback-react-select'
                          loadOptions={loadOptions}
                          value={currentCustomer}
                          cacheOptions
                          onChange={(data: any) => {
                            onChange(data)
                            setCurrentCustomer(data)
                          }}
                          noOptionsMessage={({ inputValue }) =>
                            inputValue ? <div onClick={() => setOpenCustomerModel(true)} className='cursor-pointer' style={{color: 'rgb(89, 89, 196)', display: 'flex', justifyContent: 'start'}}>
                                          <i className="fa-solid fa-plus me-3 d-flex align-items-center" style={{color: 'rgb(89, 89, 196)'}}></i>
                                          Add New <b className='m-0 ms-2'>{customerSearch}</b>
                                        </div> : 'Start typing to search...'
                          }
                        />
                      }
                    />
                    {errors?.customer && (<small className="text-danger ml-2 ">Customer is a required field</small>)}
                  </Col>
                  <Col md='4' className='mb-8 mt-2'>
                    <Label className='form-label' for='status'>Status</Label>
                    <Controller name='status' control={control} rules={{required: true}} defaultValue=''
                      render={({ field: { onChange } }) => 
                        <ReactSelect
                          isClearable={false}
                          className='react-select booking_zindex_status'
                          classNamePrefix='select'
                          theme={selectThemeColors}
                          options={statusData}
                          value={currentStatus}
                          styles={errors.status && selectStyle}
                          onChange={(data: any) => {
                            onChange(data)
                            setCurrentStatus(data)
                          }}
                        />
                      }
                    />
                    {errors?.status && (<small className="text-danger ml-2 ">Status is a required field</small>)}
                  </Col>
                  <Col className='col-4 mb-8 mt-2'>
                    <Label className='form-label' for='charge_type'>Charge Type</Label>
                    <ReactSelect
                      isClearable={false}
                      className='react-select booking_zindex'
                      classNamePrefix='select'
                      theme={selectThemeColors}
                      options={priceTypeData}
                      value={priceType}
                      styles={errors.status && selectStyle}
                      onChange={(data: any) => {
                        setPriceType(data)
                        if (data?.value === 0) {
                          setValue('price', priceData?.job_price || 0)
                          setJobPrice(priceData?.job_price || 0)
                        } else if (data?.value === 1) {
                          setValue('price', priceData?.job_leg_sum || 0)
                          setJobPrice(priceData?.job_leg_sum || 0)
                        }
                      }}
                    />
                  </Col>
                  {/* <Row>
                    <div className="d-flex ps-1" style={{width: '200px'}}>
                      <Input type="radio" name="chargeGroup" value='price_by_job' checked={priceType === 'price_by_job'} className="radio-item me-3" onChange={() => { setValue('price', priceData?.job_price || 0); setPriceType('price_by_job') }}  />
                      <Label className='form-label' for='customer'>Price By Booking</Label>
                    </div>
                    <div className="d-flex ps-1" style={{width: '200px'}}>
                      <Input type="radio" name="chargeGroup" value='price_by_job_legs' checked={priceType === 'price_by_job_legs'} className="radio-item me-3" onChange={() => { setValue('price', priceData?.job_leg_sum || 0); setPriceType('price_by_job_legs') }} />
                      <Label className='form-label' for='customer'>Price By Jobs</Label>
                    </div>
                  </Row> */}
                  <Col className='col-4 mb-8 mt-2'>
                    <Label className='form-label' for='price'>Charge</Label>
                    <Controller name='price' control={control} rules={{ required: false }} defaultValue=''
                      render={({ field }) => (
                        <Input type='number' min={0} placeholder='Enter Charge' value={jobPrice} 
                          disabled={priceType?.value === 1} 
                          invalid={ errors.price && true } 
                          onChange={(e) => {
                            field.onChange(e.target.value)
                            setJobPrice(e.target.value)} 
                          }
                        />
                      )}
                    />
                    {errors.price && (<small className="text-danger ml-2">Charge is a required field</small>)}
                  </Col>
                  {/* <Col className='col-4 mb-6'>
                    <Label className='form-label' for='tag'>Tag</Label>
                    <ReactSelect
                      isMulti
                      id='task-tags'
                      className='react-select booking_zindex'
                      classNamePrefix='select'
                      isClearable={false}
                      isSearchable={false}
                      options={tagData}
                      theme={selectThemeColors}
                      value={currentTag}
                      onChange={data => {
                        setCurrentTag(data as TagOption[])
                      }}
                    />
                  </Col> */}
                  <Col className='col-4 mb-8 mt-2'>
                    <Label className='form-label' for='price'>Purchase Order</Label>
                    <Controller name='purchage_order' control={control} rules={{ required: false }} defaultValue=''
                      render={({ field }) => (
                        <Input type='text' min={0} placeholder='Enter Purchase Order' value={purchaseOrder} 
                          invalid={ errors.price && true } 
                          onChange={(e) => {
                            field.onChange(e.target.value)
                            setPurchaseOrder(e.target.value)} 
                          }
                        />
                      )}
                    />
                    {errors.price && (<small className="text-danger ml-2">Purchase Order is a required field</small>)}
                  </Col>
                  <Col md='12' className='mb-8'>
                    <Label className='form-label' for='notes'>Booking Notes</Label>
                    <Input type='textarea' rows='2' placeholder='Enter Notes' value={notes} onChange={(e) => setNotes(e.target.value)} />
                  </Col>
              </Row>
              { id &&
                <Row>
                  <div className='job_table mt-3' style={{ maxHeight: '50vh', minHeight: '200px', overflowY: 'scroll' }}>
                    <Table className='w-100 job_table'>
                      <thead style={{zIndex: 2}}>
                        <tr>
                          <th className='ps-5'>Job ID</th>
                          <th >Pick Up</th>
                          <th >Drop Off</th>
                          <th >Start</th>
                          <th >End</th>
                          <th >PAX</th>
                          <th >Vehicles Req</th>
                          {/* <th >Vehicle</th>
                          <th >Driver</th> */}
                          <th ></th>
                        </tr>
                      </thead>
                      <tbody>
                        {jobLegByJob?.map((item: any, i: any) => {
                          // const pax = item?.pax == null || item?.pax == 'null' ? 'N/A' : item?.pax; 
                          return (
                            <tr key={i}>
                              <td className='px-5 ' onClick={() => navigate(`/jobs/${item?.id}/edit?booking=${id}`)}>
                                <div style={{maxWidth: '140px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} className='pt-3 fw-bold cursor-pointer'>{item?.job_number}</div>
                                <div className=''>{item?.status?.label}</div>
                              </td>
                                {/* <div className='pt-3' style={{maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{item?.pick_up_location}</div> */}
                              {/* <td style={{width: '250px'}}>
                                <Controller name={`jobs.${i}.pick_up`} control={control} defaultValue=''
                                  render= {({ field }) => (
                                    <AsyncSelect
                                      isClearable={true}
                                      className='react-select w-100'
                                      classNamePrefix='select'
                                      placeholder='Search From'
                                      isRtl={false}
                                      name='callback-react-select'
                                      loadOptions={loadLocationOptions}
                                  
                                      {...field}
                                      cacheOptions
                                      onChange={(data: any) => {
                                        field.onChange(data)
                                        
                                      }}
                                      noOptionsMessage={() => 'No options'}
                                    />
                                  )}
                                /> 
                              </td> */}
                              <td style={{width: '250px'}}>
                                <Controller 
                                  name={`jobs.${i}.pick_up_location`} 
                                  control={control} 
                                  defaultValue=''
                                  render={({ field }) => (
                                    <AsyncCreatableSelect
                                      id='task-tags'
                                      {...field}
                                      className='react-select'
                                      classNamePrefix='select'
                                      isClearable={false}
                                      isSearchable={true}
                                      loadOptions={loadLocationOptions}
                                      theme={selectThemeColors}
                                      onChange={(data: any) => {
                                        field.onChange(data)
                                        console.log('first')
                                      }}
                                    />

                                    // <Input placeholder='Enter Pick Up Location' type='text'
                                    //   {...field} 
                                    //   onChange={(e) => { 
                                    //     field.onChange(e.target.value)
                                    //   }}
                                    // />
                                  )}
                                />
                              </td>
                              <td style={{width: '250px'}}>
                                <Controller 
                                  name={`jobs.${i}.drop_off_location`} 
                                  control={control} 
                                  defaultValue=''
                                  render={({ field }) => (
                                    <AsyncCreatableSelect
                                      id='task-tags'
                                      {...field}
                                      className='react-select'
                                      classNamePrefix='select'
                                      isClearable={false}
                                      isSearchable={true}
                                      loadOptions={loadLocationOptions}
                                      theme={selectThemeColors}
                                      onChange={(data: any) => {
                                        field.onChange(data)
                                      }}
                                    />

                                    // <Input placeholder='Enter Drop Off Location' type='text'
                                    //   {...field} 
                                    //   onChange={(e) => { 
                                    //     field.onChange(e.target.value)
                                    //   }}
                                    // />
                                  )}
                                />
                              </td>
                              {/* <td style={{width: '250px'}}>
                                <Controller name={`jobs.${i}.drop_off`} control={control} defaultValue=''
                                  render= {({ field }) => (
                                    <AsyncSelect
                                      isClearable={true}
                                      className='react-select w-100'
                                      classNamePrefix='select'
                                      placeholder='Search drop'
                                      isRtl={false}
                                      name='callback-react-select'
                                      loadOptions={loadLocationOptions}
                                      {...field}
                                      cacheOptions
                                      onChange={(data: any) => {
                                        field.onChange(data)
                                      }}
                                      noOptionsMessage={() => 'No options'}
                                    />
                                  )}
                                /> 
                              </td> */}
                                {/* <div className='pt-3' style={{maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{item?.drop_off_location}</div> */}
                              <td style={{width: '300px'}}>
                                <div className='d-flex'>
                                  <Col md='8' style={{marginRight: '5px'}}>
                                    <Controller name={`jobs.${i}.start_at_date`} control={control} defaultValue=''
                                      render= {({ field }) => (
                                        <Flatpickr 
                                          className='form-control cursor-pointer'
                                          placeholder='Select Start Date'
                                          // value={item?.start_at}
                                          {...field}
                                          options={{ 
                                            enableTime: false, 
                                            altInput: true, 
                                            dateFormat: 'Y-m-d H:i',
                                            disableMobile : true
                                          }}
                                          onChange={(value) => {
                                            field.onChange(moment(value[0]).format('YYYY-MM-DD'))
                                            setValue(`jobs.${i}.end_at_date`, moment(value[0]).format('YYYY-MM-DD'))
                                          }}
                                          // onClose={(e) => changeDate('start_at', e, item)}
                                        />
                                      )}
                                    />
                                  </Col>
                                  <Col md='4'>
                                    <Controller name={`jobs.${i}.start_at_time`} control={control} defaultValue=''
                                      render= {({ field }) => (
                                        <Input type='time' className='cursor-pointer'
                                          {...field} 
                                          onChange={(e) => { 
                                            field.onChange(e.target.value)
                                            setValue(`jobs.${i}.end_at_time`, e.target.value)
                                          }}
                                        />
                                      )}
                                    />
                                  </Col>
                                </div>
                              </td>
                              <td style={{width: '300px'}}>
                                <div className='d-flex'>
                                  <Col md='8' style={{marginRight: '5px'}}>
                                    <Controller name={`jobs.${i}.end_at_date`} control={control} defaultValue=''
                                      render= {({ field }) => (
                                        <Flatpickr 
                                          className='form-control cursor-pointer' 
                                          placeholder='Select end Date'
                                          // value={item?.end_at}
                                          {...field}
                                          options={{ 
                                            enableTime: false, 
                                            altInput: true, 
                                            minDate: watchQs[i].start_at,
                                            dateFormat: 'Y-m-d H:i',
                                            disableMobile : true
                                          }}
                                          onChange={(value) => {
                                            field.onChange(moment(value[0]).format('YYYY-MM-DD'))
                                          }}
                                          // onClose={(e) => changeDate('end_at', e, item)}
                                        />
                                      )}
                                    />
                                  </Col>
                                  <Col md='4'>
                                    <Controller name={`jobs.${i}.end_at_time`} control={control} defaultValue=''
                                      render= {({ field }) => (
                                        <Input type='time' className='cursor-pointer'
                                          {...field} 
                                          onChange={(e) => { 
                                            field.onChange(e.target.value)
                                          }}
                                        />
                                      )}
                                    />
                                  </Col>
                                </div>
                              </td>
                              <td style={{width: '100px'}}>
                                <Controller
                                  name={`jobs.${i}.pax`}
                                  control={control}
                                  defaultValue=''
                                  render={({ field }) => (
                                    <Input placeholder='Enter PAX' type='number' min={0}
                                      // value={item?.pax}
                                      onKeyDown={(e) => preventDefualt(e)}
                                      {...field}
                                      onChange={(e) => {
                                        field.onChange(e.target.value)
                                        // if (check.timeout) clearTimeout(check.timeout)
                                        // setCheck({
                                        //   timeout: setTimeout(() => {
                                        //     changeDate('pax', Number(e.target.value), item)
                                        //   }, 1000)
                                        // })
                                      }}
                                    />
                                  )}
                                />
                              </td>
                              <td style={{width: '100px'}}>
                                <Controller 
                                  name={`jobs.${i}.vehicles_required`} 
                                  control={control} 
                                  defaultValue=''
                                  render={({ field }) => (
                                    <Input placeholder='Enter Vehicles Required' type='number' min={1}
                                      // value={item?.vehicles_required}
                                      onKeyDown={(e) => preventDefualt(e)}
                                      {...field} 
                                      onChange={(e) => { 
                                        if (e.target.value === '') {
                                          e.preventDefault();
                                          return false;
                                        }
                                        field.onChange(e.target.value)
                                        // if (check.timeout) clearTimeout(check.timeout)
                                        // setCheck({
                                        //   timeout: setTimeout(() => {
                                        //     changeDate('vehicles_required', Number(e.target.value), item)
                                        //   }, 1000)
                                        // })
                                      }}
                                    />
                                  )}
                                />
                              </td>
                              {/* <td>
                                <div className='pt-3'>
                                  {item?.driver_vehicles_arr?.map((data: any, i: any) => {
                                    if (data?.vehicle?.vehicle_name) {
                                      return `${data?.vehicle?.vehicle_name}${i < item?.driver_vehicles_arr?.length - 1 ? ",  " : ""}`
                                    }
                                  })}  
                                </div>
                              </td>
                              <td>
                                <div className='pt-3'>
                                  {item?.driver_vehicles_arr?.map((data: any, i: any) => {
                                    if (data?.driver?.driver_name) {
                                      return `${data?.driver?.driver_name}${i < item?.driver_vehicles_arr?.length - 1 ? ",  " : ""}`
                                    }
                                  })}
                                </div>
                              </td> */}
                              <td> 
                                <div className='column-action pt-3'>
                                  <UncontrolledDropdown>
                                    <DropdownToggle tag='span'>
                                      {/* <MoreVertical size={15} className='cursor-pointer' /> */}
                                      <Plus size={18} className='cursor-pointer' />
                                    </DropdownToggle>
                                    <DropdownMenu end>
                                      <DropdownItem className='w-100 dropdownItem' onClick={() => createCloneJob(item, 'add_job')}>
                                        <span className='align-middle'>Add New Leg</span>
                                      </DropdownItem>
                                      <DropdownItem className='w-100 dropdownItem' onClick={() => createCloneJob(item, 'return_job')}>
                                        <span className='align-middle'>Add Return Leg</span>
                                      </DropdownItem>
                                      <DropdownItem className='w-100 dropdownItem' onClick={() => deleteJobConfirmation(item?.id)}>
                                        <span className='align-middle'>Delete Leg</span>
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                    {jobLegByJob?.length === 0 && (
                      <>
                        <div className='d-flex justify-content-end me-5'>
                          <Plus size={18} style={{cursor: createJobLoader ? 'not-allowed' : 'pointer'}} onClick={createNewJob} />
                        </div>
                        <div className='d-flex justify-content-center'>
                          <p className='md-2 py-50 mt-2'>There are no Jobs to display</p>
                        </div>
                      </>
                    )}
                  </div>
                </Row>
              }
              <div className='d-flex justify-content-between mt-10'>
                <div>
                  { id &&
                    <>
                      {/* <Button type="button" color="primary" onClick={() => { navigate(`/jobs/add?booking=${id}`); localStorage.setItem('booking', JSON.stringify({ value: priceData?.id, label: priceData?.booking_number })) }} style={{ marginRight: "10px" }}>
                        Add Job
                      </Button> */}
                      <Button type="button" color="danger" onClick={() => setCenteredModal(true)} style={{ marginRight: "10px" }}>
                        Delete
                      </Button>
                      <Button type="button" color="primary" onClick={() => setOpenInvoice(true)} >
                        Invoice
                      </Button>
                    </>
                  }
                </div>
                <div>
                  <Button type="button" color="secondary" onClick={() => navigate("/booking")} style={{ marginRight: "10px" }}>
                    Close
                  </Button>
                  <Button type='submit' color='primary' disabled={loader} className='btn-next'>
                    { loader ? <Spinner size='sm'/> : <span className='align-middle d-sm-inline-block d-none'>Save</span>}
                  </Button>
                </div>
              </div>
            </Form>
          </UILoader>
        </Card>
        <InvoiceModel 
          openInvoice={openInvoice}
          setOpenInvoice={setOpenInvoice}
          priceData={priceData}
          setRenderElementService={setRenderElementService}
        />
      </Fragment>
    </>
  )
}

export {AddJob}