import React, { Fragment, useEffect, useState } from 'react'
import { Col, Card, Form, Input, Label, Row, Button, Spinner, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import { Controller, useForm } from "react-hook-form"
import { useNavigate, useParams } from 'react-router'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import toast from 'react-hot-toast'
import { CustomerService } from '../../modules/Services/Customers'
import ReactSelect from 'react-select'
import { selectStyle, selectThemeColors } from '../../modules/commonModel/Utils'
import { LocationService } from '../../modules/Services/Location'
import ConfirmModal from '../../modules/commonModel/ConfirmModal'
import UILoader from '../../modules/commonModel/Ui-loader'
import ComponentSpinner from '../../modules/apps/user-management/users-list/components/loading/Loading-spinner'
import CustomerBookingDetail from './CustomerBookingDetail'

const AddCustomer = ({ customerSearch, searchBy, setSearchBy, setOpenCustomerModel, setCustomerName }) => {
  const { control, handleSubmit, setValue, reset, formState: { errors } } = useForm()
  const {customer_id} = useParams()
  const addMode = !customer_id
  const [officePhone, setOfficePhone] = useState('')
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)
  const [pending, setPending] = useState(false)
  const [currentCountry, setCurrentCountry] = useState({ value: '', label: 'Select Country'})
  const [countryData, setCountryData] = useState([])
  const [currentCounty, setCurrentCounty] = useState({ value: '', label: 'Select Country'})
  const [countyData, setCountyData] = useState([])
  const [editList, setEditList] = useState({ customer_country: null, customer_county: null, company_name: null })
  const [countryChange, setCountryChange] = useState(true)
  const [centeredModal, setCenteredModal] = useState(false)
  const [editLoader, setEditLoader] = useState(false)
  const [customerType, setCustomerType] = useState('individual')
  const [activeTab, setActiveTab] = useState("1")
  const phoneStyle = { backgroundColor:"white", backgroundClip:"padding-box", border:"1px solid #d8d6de", borderRadius:"0.357rem", width:"100%" }
  const entity_id = localStorage.getItem('entity_id')
  const EmailPattern = /^[a-zA-Z0-9.'’‘’'_%+-]+@[a-zA-Z0-9.'’‘’'-]+\.[a-z]{0,30}$/
  const url = window.location.href;

  function fetchFormData(data: any) {
    setLoader(true)
    const obj = {
      entity_id: entity_id,
      is_company: customerType === 'company' ? 1 : customerType === 'individual' ? 0 : null,
      customer_name: data?.name,
      customer_email: data?.email,
      customer_mobile: officePhone,
      customer_address_1: data?.address_line_1,
      customer_address_2: data?.address_line_2,
      customer_city: data?.city,
      customer_postcode: data?.postCode,
      company_name: customerType === 'company' ? data?.company_name : null,
      customer_country: currentCountry?.value,    
      customer_county: currentCounty?.value    
    }
    if (customer_id) delete obj.entity_id

    CustomerService[customer_id ? 'updateCustomer' : 'storeCustomer'](entity_id, obj, customer_id)
      .then((res: any) => {
        toast.success(res?.data?.message)
        setCustomerName({ value: res?.data?.response?.id, label: customerType === 'individual' ? res?.data?.response?.customer_name : res?.data?.response?.company_name })
        setSearchBy(res?.data?.response?.is_company === 1 ? 'company_name' : res?.data?.response?.is_company === 0 ? 'customer_name' : 'customer_name')
        // setCustomerName({ value: res?.data?.response?.id, label: res?.data?.response?.customer_name })
        // setSearchBy('customer_name')
        url.includes('customers') ? navigate("/customers") : setOpenCustomerModel(false)
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message)
      })
      .finally(() => setLoader(false))
  }

  function editData() {
    setEditLoader(true)
    CustomerService.editCustomer(entity_id, customer_id)
      .then((res: any) => {
        const obj = {
          name: res.data?.response?.customer_name,
          email: res.data?.response?.customer_email,
          address_line_1: res.data?.response?.customer_address_1,
          address_line_2: res.data?.response?.customer_address_2,
          city: res.data?.response?.customer_city,
          postCode: res.data?.response?.customer_postcode,
          mobile: res.data?.response?.customer_mobile,
          company_name: res?.data?.response?.company_name,
          country: '',
          county: '',
        }
        setEditList(res.data?.response)
        // countryData?.map((item: any) => {
        //   if (Number(res?.data?.response?.customer_country) === item?.value) {
        //     obj.country = item
        //     setCurrentCountry(item)
        //   }
        // })
        // countyData?.map((item: any) => {
        //   if (Number(res?.data?.response?.customer_county) === item?.value) {
        //     obj.county = item
        //     setCurrentCounty(item)
        //   }
        // })
        reset(obj)
        setCustomerType(res?.data?.response?.is_company === 1 ? 'company' : res?.data?.response?.is_company === 0 ? 'individual' : '')
        setOfficePhone(res.data?.response?.customer_mobile)
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message)
      })
      .finally(() => setEditLoader(false))
  }

  useEffect(() => {
    if (customer_id) {
      editData()
    }
  }, [customer_id])

  useEffect(() => {
    if (countryChange && editList && countryData?.length >= 0 && countyData?.length >= 0) {
      countryData?.map((item: any) => {
        if (Number(editList?.customer_country) === item?.value) {
          setValue('country', item)
          setCurrentCountry(item)
        }
      })
      countyData?.map((item: any) => {
        if (Number(editList?.customer_county) === item?.value) {
          setValue('county', item)
          setCurrentCounty(item)
        }
      })
    }
  }, [editList, countryData, countyData])

  function getCountyList() {
    LocationService.getCountyList(entity_id, { country_id: currentCountry?.value })
    .then((res: any) => {
      const countyList = res?.data?.response?.map((item: any) => {
        return { value: item?.id, label: item?.name }
      })
      setCountyData(countyList)
    })
    .catch((err: any) => {
      toast.error(err?.response?.data?.message)
    })
  }

  useEffect(() => {
    if (currentCountry?.value) getCountyList()
  }, [currentCountry])

  function getCountryList() {
    LocationService.getCountryList(entity_id)
    .then((res: any) => {
      const countryList = res?.data?.response?.map((item: any) => {
        return { value: item?.id, label: item?.name }
      })
      setCountryData(countryList)
    })
    .catch((err: any) => {
      toast.error(err?.response?.data?.message)
    })
  }

  useEffect(() => {
    getCountryList()
  }, [])

  useEffect(() => {
    if (addMode) {
      reset({ mobile: 353 })
      setOfficePhone('353')
    }
  }, [addMode])

  function forceDeleteCustomer() {
    setPending(true)
    CustomerService.deleteCustomer(entity_id, customer_id)
      .then((res: any) => {
        toast.success(res?.data?.message)
        setCenteredModal(false)
        navigate('/customers')
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message)
      })
      .finally(() => setPending(false))
  }

  function RadioButton ({ name, value, checked, setChecked, label }) {
    return (
      <>
        <Input type='radio' name={name} className='me-3' value={value} checked={checked} onChange={(e) => setChecked(e.target.value)} />
        <Label className='form-label' for={name}>{label}</Label>
      </>
    )
  }

  useEffect(() => {
    if(customerType === 'individual') {
      setValue('company_name', customer_id ? editList?.company_name : null)
    }
  }, [customerType])

  useEffect(() => {
    if (customerSearch && customerType === 'individual') {
      setValue('name', customerSearch)
      setValue('company_name', '')
    } else if(customerSearch && customerType === 'company') {
      setValue('company_name', customerSearch)
      setValue('name', '')
    }
  }, [customerType])

  useEffect(() => {
    if (customerSearch) {
      if (searchBy === 'customer_name') {
        setCustomerType('individual')
        setValue('name', customerSearch)
        setValue('company_name', '')
      } else if (searchBy === 'company_name') {
        setCustomerType('company')
        setValue('company_name', customerSearch)
        setValue('name', '')
      }
    }
  }, [customerSearch])

  return (
    <>
      <Fragment>
        <ConfirmModal
          isOpen={centeredModal}
          onHide={() => setCenteredModal(false)}
          ondelete={forceDeleteCustomer}
          loader={pending}
        />
        <Card className='p-10'>
          <Nav pills style={{padding:'5px', backgroundColor:'#efefef', borderRadius: '5px'}}>
            <NavItem>
              <NavLink active={activeTab === "1"} onClick={() => setActiveTab("1")}>
                Profile
              </NavLink>
            </NavItem>
            {customer_id &&
              <NavItem>
                <NavLink active={activeTab === "2"} onClick={() => setActiveTab("2")}>
                  Bookings
                </NavLink>
              </NavItem>
            }
          </Nav>
          <Row className='mt-10'>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <UILoader blocking={editLoader} loader={<ComponentSpinner />} overlayColor='rgba(245, 245, 240, .1)' className={editLoader ? 'loader-blur' : ''}>
                  <Form onSubmit={handleSubmit(fetchFormData)}>
                    <Row>
                      <Col md='12' className='mb-2 d-flex'>
                        <div className='me-10'>
                          <RadioButton name='companyType' value={'individual'} checked={customerType === 'individual'} setChecked={setCustomerType} label='Individual' />
                        </div>
                        <div>
                          <RadioButton name='companyType' value={'company'} checked={customerType === 'company'} setChecked={setCustomerType} label='Company' />
                        </div>
                      </Col>
                      {customerType === 'company' &&
                        <Col md='6' className='mb-4'>
                          <Label className='form-label' for='company_name'>Company Name</Label>
                          <Controller name='company_name' control={control} rules={{required: false}} defaultValue=''
                            render= {({ field }) => (
                              <Input placeholder='Enter Company' {...field} />
                            )}
                          />
                          {errors?.company_name && (<small className="text-danger ml-2 ">Company Name is a required field</small>)}
                        </Col>
                      }
                      <Col md= '6' className='mb-4'>
                        <Label className='form-label' for='name'>Contact Name</Label>
                        <Controller name='name' control={control} rules={{required: true}} defaultValue=''
                          render={({ field }) => <Input placeholder='Enter Name' invalid={ errors.name && true } {...field} />} 
                        />
                        {errors.name && (<small className="text-danger ml-2 ">Contact Name is a required field</small>)}
                      </Col>
                      {customerType === 'individual' &&
                        <Col md='6' className='mb-4'></Col>
                      }
                      <Col md='6' className='mb-4'>
                        <Label className='form-label' for='address_line_1'>Address Line 1</Label>
                        <Controller name='address_line_1' control={control} rules={{required: false}} defaultValue=''
                          render={({ field }) => <Input placeholder={'98  Borough bridge Road, Birmingham'} invalid={errors.address_line_1 && true} {...field} />}
                        />
                        {errors?.address_line_1 && (<small className="text-danger ml-2 ">Address Line 1 is a required field</small>)}
                      </Col>
                      <Col md='6' className='mb-4'>
                        <Label className='form-label' for='address_line_2'>Address Line 2</Label>
                        <Controller name='address_line_2' control={control} rules={{required: false}} defaultValue=''
                          render={({ field }) => <Input placeholder={"98 Borough bridge Road, Birmingham"} invalid={errors.address_line_2 && true} {...field} />}
                        />
                      </Col>
                      <Col md='6' className='mb-4'>
                        <Label className='form-label' for='city'>City</Label>
                        <Controller name='city' control={control} rules={{required: false}} defaultValue=''
                          render={({ field }) => <Input placeholder='City' invalid={errors.city && true} {...field} />}
                        />
                        {errors?.city && (<small className="text-danger ml-2 ">City is a required field</small>)}
                      </Col>
                      <Col md='6' className='mb-4'>
                        <Label className='form-label' for='postCode'>Post Code</Label>
                        <Controller name='postCode' control={control} rules={{ required: false }} defaultValue=''
                          render={({ field }) => (
                            <Input invalid={errors.postCode && true} name='postCode' placeholder="SW1A 2AA" styles={errors.postCode} {...field} />
                          )}
                        />
                        {errors?.postCode && (<small className="text-danger ml-2 ">Post Code is a required field</small>)}
                      </Col>
                      <Col md='6' className='mb-6'>
                        <Label className='form-label' for='country'>Country</Label>
                        <Controller name='country' control={control} rules={{required: false}} defaultValue=''
                          render= {({ field: { onChange } }) => (
                            <ReactSelect
                              isClearable={false}
                              className='react-select'
                              classNamePrefix='select'
                              theme={selectThemeColors}
                              options={countryData}
                              value={currentCountry}
                              styles={errors.country && selectStyle}
                              onChange={(data: any) => {
                                onChange(data)
                                setCountryChange(false)
                                setCurrentCountry(data)
                                setCurrentCounty({ value: '', label: 'Select Country'})
                                setValue('county', '')
                              }}
                            />
                          )}
                        /> 
                        {errors?.country && (<small className="text-danger ml-2 ">Country is a required field</small>)}
                      </Col>
                      <Col md='6' className='mb-6'>
                        <Label className='form-label' for='county'>County</Label>
                        <Controller name='county' control={control} rules={{required: false}} defaultValue=''
                          render= {({ field: { onChange } }) => (
                            <ReactSelect
                              isClearable={false}
                              className='react-select'
                              classNamePrefix='select'
                              theme={selectThemeColors}
                              options={countyData}
                              value={currentCounty}
                              styles={errors.county && selectStyle}
                              onChange={(data: any) => {
                                onChange(data)
                                setCurrentCounty(data)
                              }}
                            />
                          )}
                        /> 
                        {errors?.county && (<small className="text-danger ml-2 ">County is a required field</small>)}
                      </Col>
                      <Col md='6' className='mb-4'>
                        <Label className='form-label' for='email'>Email</Label>
                        <Controller name='email' control={control} defaultValue='' rules={{required : false, pattern: EmailPattern}}
                          render={({ field }) => ( 
                            <Input placeholder='johndoe@gmail.com' invalid={errors.email && true} {...field} />)} 
                          />
                          {errors.email?.type === 'required' && (<small className="text-danger ml-2">Email is a required field</small>)}
                          {errors.email?.type === 'pattern' && (<small className="text-danger ml-2">Invalid Email Address</small>)}
                      </Col>
                      <Col md='6' className='mb-4'>
                        <Label className='form-label' for='Mobile'>Mobile</Label>
                        <Controller name='mobile' control={control} defaultValue='' rules={{required : false}}
                          render={({ field: {onChange} }) => ( 
                          // <PhoneInput countryCodeEditable={false} inputStyle={phoneStyle} value={officePhone} onChange={e => { onChange(e); setOfficePhone(e) }} />
                          <PhoneInput inputStyle={phoneStyle} value={officePhone} onChange={e => { onChange(e); setOfficePhone(e) }} />
                        )} />
                          {errors.mobile && (<small className="text-danger ml-2">Mobile is a required field</small>)}
                      </Col>
                    </Row>
                    <div className='d-flex justify-content-between'>
                      <div>
                        { (customer_id && url.includes('customers')) &&
                          <Button type="button" color="danger" onClick={() => setCenteredModal(true)} >
                            Delete
                          </Button>
                        }
                      </div>
                      <div>
                        <Button type="button" color="secondary" onClick={() => url.includes('customers') ? navigate("/customers") : setOpenCustomerModel(false)} style={{ marginRight: "10px" }}>
                          Close
                        </Button>
                        <Button type='submit' color='primary' disabled={loader} className='btn-next'>
                          { loader ? <Spinner size='sm'/> : <span className='align-middle d-sm-inline-block d-none'>Save</span>}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </UILoader>
              </TabPane>
              <TabPane tabId='2'>
                <CustomerBookingDetail />
              </TabPane>
            </TabContent>
          </Row>
        </Card>
      </Fragment>
    </>
  )
}

export {AddCustomer}