import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Card, Col, Row } from 'reactstrap'
import { CustomerService } from '../../modules/Services/Customers'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import { Icon } from '@iconify/react';
import { FilterDetails } from '../../App'

function CustomerBookingDetail() {
    const entity_id = localStorage.getItem('entity_id')
    const [filterValue, setFilterValue] = useContext<any>(FilterDetails)
    const {customer_id} = useParams()
    const [customerBookingData, setCustomerBookingData] = useState([])
    const [pending, setPending] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [renderElementService, setRenderElementService] = useState(1)
    const [nextPage, setNextPage] = useState(null)
    const navigate = useNavigate()

    const getBookingData = () => {
        setPending(true)
        const data = {
            per_page: 10,
            page: currentPage
        }
        CustomerService.getBookingsByCustomer(entity_id, customer_id, data)
        .then((res: any) => {
            if (currentPage > 1) setCustomerBookingData(pre => [...pre, ...res?.data?.response?.data]) 
            else setCustomerBookingData(res?.data?.response?.data)
            setNextPage(res?.data?.response?.next_page_url)
        })
        .catch((err: any) => {
          toast.error(err?.response?.data?.message)
        })
        .finally(() => setPending(false))
    }

    useEffect(() => {
        if (renderElementService && customer_id) {
          setRenderElementService(0)
          getBookingData()
        }
    }, [renderElementService])

    function handleScroll(event: any) {
        const scrollHeight = event.target.scrollTop + event.target.clientHeight
        const elementHeight = event.target.scrollHeight
        if (event.target.scrollTop > 0) {
            if (((scrollHeight + 100) >= elementHeight) && nextPage && !pending && !renderElementService) {
                setCurrentPage((p: any) => p + 1)
                setRenderElementService((prev) => prev + 1)
            }
        }
    }
    
    return (
        <Fragment>
            <div onScroll={(e) => handleScroll(e)} id='vehicle_table' className='jobs-table-style'>
                {customerBookingData?.length ? customerBookingData?.map((item: any, i: any) => {
                    return (
                        <Card className='p-5 mb-3' >
                            <div key={i} className='d-flex justify-content-between w-100 pb-3' style={{borderBottom: '1px solid #e0e0e0'}}>
                                <div className='col-1'>
                                    <h3 className='text-gray-700 cursor-pointer' onClick={() => navigate(`/booking/${item?.id}/edit`)}>
                                        {item?.booking_number}
                                    </h3>
                                </div>
                                <div className='col-1'>
                                    <h3 className='text-gray-700'>
                                        <Icon icon="material-symbols:euro" /> {item?.job_price}
                                    </h3>
                                </div>
                                <div className='col-9'>
                                   
                                </div>
                                <div className='col-1 ps-5'>
                                    <h3 className='text-gray-700'>
                                        {item?.job_status?.label}
                                    </h3>
                                </div>
                            </div>
                                
                            {item?.coachiejoblegs?.map((data: any, index: any) => {
                                return (
                                    <Row key={index} className='mt-5'>
                                        <Col xs={1}>
                                            <p style={{marginBottom: '5px'}} className='cursor-pointer' onClick={() => navigate(`/jobs/${data?.id}/edit`)}>{data?.job_number}</p>
                                        </Col>
                                        <Col xs={3}>
                                            <p style={{marginBottom: '5px'}}>
                                                {data?.pick_up_location &&
                                                    <>
                                                        <Icon icon="bi:pin-map-fill" style={{color: '#f68716'}} /> 
                                                        <span className='ms-2'>{data?.pick_up_location}</span>
                                                    </>
                                                }
                                            </p>
                                            <p style={{marginBottom: '5px'}}>
                                                {data?.drop_off_location &&
                                                    <>
                                                        <Icon icon="bi:arrow-right-circle-fill" style={{color: '#f68716'}} />
                                                        <span className='ms-2'>{data?.drop_off_location}</span> 
                                                    </>
                                                }
                                            </p>
                                        </Col>
                                        <Col xs={2}>
                                            <span style={{marginBottom: '5px'}}>
                                                {data?.start_at_time &&
                                                    <div className='cursor-pointer' onClick={() => { setFilterValue({...filterValue, planner: {...filterValue?.planner, date: data?.end_at_date ? data?.end_at_date : '' }}); navigate(`/planner`)} }>
                                                        <Icon icon="mdi:clock" style={{color: '#f68716'}} />
                                                        <span className='ms-2'>{data?.start_at_date ? moment(data?.start_at_date).format("DD-MM-YYYY") : ''} {" "} {data?.start_at_time}</span>
                                                    </div>
                                                }
                                            </span>
                                            <p style={{marginBottom: '5px'}}>
                                                {data?.end_at_date &&
                                                    <div className='cursor-pointer' onClick={() => { setFilterValue({...filterValue, planner: {...filterValue?.planner, date: data?.end_at_date ? data?.end_at_date : '' }}); navigate(`/planner`)} }>
                                                        <Icon icon="mdi:clock" style={{color: '#f68716'}} />
                                                        <span className='ms-2'>{data?.end_at_date ? moment(data?.end_at_date).format("DD-MM-YYYY") : ''} {' '} {data?.end_at_time}</span>
                                                    </div>
                                                }
                                            </p>
                                        </Col>
                                        <Col xs={1}>
                                            {data?.pax &&
                                                <>
                                                    <Icon icon="ion:people" style={{color: '#f68716'}} />
                                                    <span className='ms-2'>{data?.pax}</span>
                                                </>
                                            }
                                        </Col>
                                        <Col xs={2} className='d-flex'>
                                            {data?.driver_vehicles_arr?.some((item: any) => item?.vehicle?.vehicle_name) &&
                                                <span>
                                                    <Icon icon="bi:bus-front-fill" style={{color: '#f68716'}} />
                                                </span>
                                            }
                                            <span className='ms-2'>
                                                {data?.driver_vehicles_arr?.map((item: any, i: any) => {
                                                    return (
                                                        <div key={i}>{item?.vehicle?.vehicle_name}</div>
                                                    )
                                                })}
                                            </span>
                                        </Col>
                                        <Col xs={2} className='d-flex'>
                                            {data?.driver_vehicles_arr?.some((item: any) => item?.driver?.driver_name) &&
                                                <span>
                                                    <Icon icon="mdi:drivers-license" style={{color: '#f68716'}} />
                                                </span>
                                            }
                                            <span className='ms-2'>
                                                {data?.driver_vehicles_arr?.map((item: any, i: any) => {
                                                    return (
                                                        <div key={i}>
                                                            {item?.driver?.driver_name}
                                                        </div>
                                                    )
                                                })}
                                            </span>
                                        </Col>
                                        <Col xs={1}>
                                            <span className="badge badge-light-success p-2">{data?.status?.label}</span>
                                        </Col>
                                    </Row>
                                )
                            })}
                        </Card>
                    )
                }) : <p className='d-flex justify-content-center mt-2'>There are no records to display</p>}
            </div>
        </Fragment>
    )
}

export default CustomerBookingDetail